<template>
  <v-row class="pb-8">
    <v-col
      cols="12"
      sm="12"
      class="pt-0"
    >
      <v-row>
        <v-col
          xs="12"
          sm="12"
          md="12"
          align-self="center"
        >
          <session-title
            class="pt-8 px-8 pb-0"
            title="Resposta"
            description=""
          />
        </v-col>
        <v-col
          class="pt-0 px-11 pb-3"
          align-self="center"
        >
          <chat-ti-modal-access-vue />
        </v-col>
      </v-row>
      <v-container>
        <h2
          class="titulo"
        >
          {{ loggedUser }}
        </h2>
        <h3 class="titulo">
          {{ suggestionText.type }} - {{ suggestionText.category }}
        </h3>
        <h5
          v-if="suggestionText.user == 'Anônimo'"
          class="titulo"
        >
          {{ suggestionText.user }}
        </h5>
        <v-card
          class="cardResposta"
        >
          <v-row>
            <v-col
              class="suggestionText"
              cols="12"
            >
              <v-card-item>
                {{ suggestionText.text }}
              </v-card-item>
            </v-col>
          </v-row>
          <v-container
            v-for="(answer,i) in answers"
            :key="i"
          >
            <v-row
              v-if="answer.admin === 0 "
              class="respUsuario"
            >
              <v-col
                cols="6"
              >
                <v-card-subtitle
                  class="userSubtitle"
                >
                  {{ loggedUser }}
                  {{ creationDate(answer.creationDate) }}
                </v-card-subtitle>
                <v-card-item>
                  {{ answer.text }}
                </v-card-item>
              </v-col>
            </v-row>
            <v-row
              v-else
              class="respAdmin"
            >
              <v-col
                cols="6"
              />
              <v-col
                cols="6"
              >
                <v-card-subtitle
                  class="admSubtitle"
                >
                  {{ creationDate(answer.creationDate) }}
                </v-card-subtitle>
                <v-card-item>
                  {{ answer.text }}
                </v-card-item>
              </v-col>
            </v-row>
          </v-container>
          <v-row
            class="textField"
          >
            <v-text-field
              v-model="answerText.text"
              label="Enviar Resposta"
              outlined
              height="1"
              @keyup.enter="submit()"
            />
            <v-btn
              x-large
              color="secondary"
              elevation="2"
              depressed
              class="btnEnviar"
              @click="submit()"
            >
              Enviar
            </v-btn>
          </v-row>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SessionTitle from '@/components/SessionTitle'
import ChatTiModalAccessVue from "../../components/ModalViews/ChatTiModalAccess.vue";
import store from '../../store'
import { getAnswerByIdSuggestion, postAnswer } from '../../services/answer-service.js';
import Suggestion from '../../services/suggestion-service'

export default {
  name: 'AnswerSuggestion',
  components: {
    SessionTitle,
    ChatTiModalAccessVue,
  },
  data () {
    return {
      loggedUser: '',
      suggestion: [],
      suggestionText:{},
      loading: false,
      items: ['Sugestão', 'Reclamação'],
      nameless: false,
      answers: {},
      answerDate: '',
      answerText: {
        suggestionId: this.$route.params.id,
        text: null,
        admin: 0,
      },
    }
  },
  async created() {
    this.getLoggedUser();
    try{
      const id = this.$route.params.id
     this.answers = await getAnswerByIdSuggestion(id);
    } catch (e) {
      this.$handleHttpError(e)
    };

    await this.loadSuggestion();

    this.updateStatus(this.$route.params.id)

  },
  methods: {
    async submit () {
      try {
        this.loading = true
        if(this.answerText.text.includes('\n')){
          this.answerText.text.replace('\n','')
        }
        await postAnswer({ ...this.answerText })
        this.answerText.text = null
        this.$toast.success('Sugestão/Reclamação cadastrada com sucesso')
        this.loading = false
        const data = await getAnswerByIdSuggestion(this.$route.params.id);
        this.answers = data;
      } catch (e) {
        this.$handleHttpError(e)
        this.loading = false
      }
    },

    async getLoggedUser(){
      this.loggedUser = store.getters['auth/userName']
    },

    creationDate(date){
      const fixDate = new Date(date).toLocaleString("pt-BR");
      return fixDate
    },

    async loadSuggestion() {
      const data = await Suggestion.getSuggestion();
      this.suggestion = data;
      this.suggestionText = this.suggestion.find(element => element.id == this.$route.params.id )
      console.log(this.suggestionText);
    },
    async updateStatus(id){
      try{
        await Suggestion.updateReadStatus(id)
      }
      catch(e){
        this.$handleHttpError(e)
      }
    }
  },

}
</script>
<style >
  .titulo{
    display: flex;
    justify-content: center;
  }
  .userSubtitle{
    text-align: left;
  }
  .respUsuario{
    padding-left: 20px;
  }
  .admSubtitle{
    text-align: right;
  }
  .respAdmin{
    text-align: right;
    padding-right: 2%;
  }
  .textField{
    margin: 0px 15px 0px;

    justify-content: space-around;
  }
  .linhaUsuario{
    justify-content: left;
  }
  .btnEnviar{
    margin-left: 15px;
  }
  .suggestionText{
    text-align: center;
  }
</style>
