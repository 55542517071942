import api from './api'

const url = 'answer'

const postAnswer = params => api.post(url, params)

const getAnswerByIdSuggestion = (id) => api.get(`${url}?id=${id}`);

export {
  postAnswer, getAnswerByIdSuggestion
 }
